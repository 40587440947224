import { fieldData } from './fieldData'
import { faUser, faEnvelope, faLockAltLight } from 'FontAwesome'

export const fields = () => {
  return [
    {
      field: fieldData.USER_ROLE,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 }
    },
    {
      field: fieldData.USER_NAME,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faUser }
    },
    {
      field: fieldData.USER_EMAIL,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faEnvelope }
    },
    {
      field: fieldData.USER_USERNAME,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faUser }
    },
    {
      field: fieldData.USER_PASSWORD,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faLockAltLight }
    }
  ]
}
