import React, { Component } from 'react'
import { fromJS } from 'immutable'
import withStyles from '@material-ui/core/styles/withStyles'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import TableList from 'components/UI/TableList'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CaseTypeSelecter from './CaseTypeSelecter'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { mergeGraphConnection } from 'helper'

const KEYS = [
  'id',
  'caseType',
  'name',
  'email',
  'address',
  'createdAt',
  'status',
  'draft'
]
const TABLE_HEAD = [
  'Sagstype',
  'Navn',
  'Email',
  'Adresse',
  'Oprettet',
  'Status',
  ''
]
const GET_CASES = gql`
  query list($caseType: String, $cursor: String) {
    listCases(first: 25, caseType: $caseType, after: $cursor) {
      edges {
        cursor
        node {
          id
          caseType
          email
          name
          address
          caseSubject
          createdAt
          status
          draft
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`
class Cases extends Component {
  state = {
    values: fromJS({ caseType: undefined })
  }

  onChangeHandler(name, value) {
    this.setState({
      values: this.state.values.set(name, value)
    })
  }

  onDateTimeChange(date, name) {
    this.onChangeHandler(name, date.toDate())
  }

  onInputChange(event) {
    const { name, value, checked } = event.target
    this.onChangeHandler(name, value || checked)
  }

  render() {
    const { values } = this.state
    const { path } = this.props
    return (
      <>
        <Card>
          <CardBody>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <CaseTypeSelecter
                  value={values}
                  onChange={event => this.onInputChange(event)}
                  label="Sagstype"
                  name="caseType"
                />
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
        <Query
          query={GET_CASES}
          variables={{ caseType: values.get('caseType') }}
        >
          {({ loading, error, data: { listCases = {} }, fetchMore }) => {
            // NOTE: need to update cache
            if (error) {
              return (
                <h2>
                  {/* NOTE: replace hack to remove server name */}
                  {/* {error.message.replace('GraphQL error: ', '')} */}
                  Der er ikke nogle sag med denne type
                </h2>
              )
            }
            return (
              <TableList
                KEYS={KEYS}
                tableHead={TABLE_HEAD}
                tableData={listCases.edges}
                isLoading={loading}
                linkTo={path}
                onLoadMore={() =>
                  fetchMore({
                    variables: {
                      cursor: listCases.pageInfo.endCursor
                    },
                    updateQuery: mergeGraphConnection('listCases')
                  })
                }
                pageInfo={listCases.pageInfo}
              />
            )
          }}
        </Query>
        {/* <TableList
                KEYS={KEYS}
                tableHead={TABLE_HEAD}
                tableData={data[Object.keys(data)[0]]}
                isLoading={loading}
                linkTo={path}
              /> */}
      </>
    )
  }
}

export default withStyles(basicsStyle)(Cases)
