// @flow
export const fieldData = {
  CASE_REF: {
    label: 'Sagsnr.:',
    id: 'caseRef',
    type: 'text'
  },
  CASE_TYPE: {
    label: 'Sagstype',
    values: ['RENT', 'UTILITIES', 'VACATING', 'DEFECTS', 'OTHERS'],
    selectLabel: ['Husleje', 'Forbrug', 'Fraflytning', 'Mangler', 'Øvrige'],
    id: 'caseType',
    type: 'select'
  },
  CASE_SUBJECT: {
    label: 'Sagsemne',
    id: 'caseSubject',
    type: 'text'
  },
  CASE_TOWN: {
    label: 'Huslejenævn (by)',
    id: 'caseTown',
    type: 'text'
  },
  ADDRESS: {
    label: 'Adresse',
    id: 'address',
    type: 'text'
  },
  SIZE: {
    label: 'Størrelse',
    id: 'size',
    type: 'number'
  },
  ROOMS: {
    label: 'Værelser',
    id: 'rooms',
    type: 'number'
  },
  COMPLETION_YEAR: {
    label: 'Opførelsesår',
    id: 'completionYear',
    type: 'number'
  },
  MODERNIZATION_YEAR: {
    label: 'Moderniseringsår',
    id: 'modernizationYear',
    type: 'number'
  },
  MODERNIZATION_AMOUNT: {
    label: 'Moderniseringsbeløb',
    id: 'modernizationAmount',
    type: 'number'
  },
  CODITION_KITCHEN_SCORE: {
    label: 'Køkken stand',
    id: 'conditionKitchenScore',
    type: 'number'
  },
  CODITION_BATH_SCORE: {
    label: 'Bad stand',
    id: 'conditionBathScore',
    type: 'number'
  },
  CODITION_OTHER_SCORE: {
    label: 'Øvrigt stand',
    id: 'conditionOtherScore',
    type: 'number'
  },
  CODITION_KITCHEN_COMMENT: {
    label: 'Køkken udrag',
    id: 'conditionKitchenComment',
    type: 'textarea'
  },
  CODITION_BATH_COMMENT: {
    label: 'Moderniseringsbeløb',
    id: 'conditionBathCommen',
    type: 'textarea'
  },
  CODITION_OTHER_COMMENT: {
    label: 'Moderniseringsbeløb',
    id: 'conditionOtherComment',
    type: 'textarea'
  },
  RENT_DECISION_BEFORE: {
    label: 'Huslejen Før',
    id: 'rentDecisionBefore',
    type: 'number'
  },
  RENT_DECISION_AFTER: {
    label: 'Husleje Efter',
    id: 'rentDecisionAfter',
    type: 'number'
  },
  DEPOSIT: {
    label: 'Depositum',
    id: 'deposit',
    type: 'number'
  },
  PREPAID: {
    label: 'Forudbetalt',
    id: 'prepaid',
    type: 'number'
  },
  CASE_FAVOR: {
    label: 'Medhold',
    values: ['LANDLORD', 'TENANT'],
    selectLabel: ['Landlord', 'Client'],
    id: 'caseFavor',
    type: 'select'
  },
  COMMENT: {
    label: 'bemærkning som trækker ned',
    id: 'comment',
    type: 'textarea'
  }
}
