// @flow

export const QUERIES = {
  // =========== SUBJECT ACCESS =========== //
  createSubjectAccess: `mutation create($input: SubjectAccessInput) {
    createSubjectAccess(input: $input) {
      id
      caseRef
      caseType
      caseFavor
      caseSubject
      address
      caseTown
      size
      rooms
      completionYear
      modernizationAmount
      modernizationYear
      conditionKitchen
      conditionKitchenScore
      conditionBath
      conditionBathScore
      conditionOther
      conditionOtherScore
      comment
      rentDecisionBefore
      rentDecisionAfter
      deposit
      prepaid
      files {
        id
        filename
      }
    }
  }`,
  getSubjectAccess: `query get($id: ID!) {
     getSubjectAccess(id: $id){
      id
      caseRef
      caseType
      caseFavor
      caseSubject
      address
      caseTown
      size
      rooms
      completionYear
      modernizationAmount
      modernizationYear
      conditionKitchen
      conditionKitchenScore
      conditionBath
      conditionBathScore
      conditionOther
      conditionOtherScore
      comment
      rentDecisionBefore
      rentDecisionAfter
      deposit
      prepaid
      files {
        id
        filename
      }
    }
  }`,
  updateSubjectAccess: `mutation update($id: ID!, $input: SubjectAccessInput) {
     updateSubjectAccess(id: $id, input: $input){
      id
      caseRef
      caseType
      caseFavor
      caseSubject
      address
      caseTown
      size
      rooms
      completionYear
      modernizationAmount
      modernizationYear
      conditionKitchen
      conditionKitchenScore
      conditionBath
      conditionBathScore
      conditionOther
      conditionOtherScore
      comment
      rentDecisionBefore
      rentDecisionAfter
      deposit
      prepaid
      files {
        id
        filename
      }
    }
  }`,

  // =========== /SUBJECT ACCESS =========== //

  // =========== RENT CHECK =========== //
  getRentCheck: `query get($id: ID!) {
    getRentCheck(id: $id){
      id
      contactName
      contactEmail
      contactPhone
      address
      addressId
      bbrSize
      size
      rent
      condition
      moveIn
      furnished
      termsAccepted

    }
   }`,

  updateRentCheck: `mutation update($id: ID!, $input: RentCheckInput) {
    updateRentCheck(id: $id, input: $input){
      contactName
      contactEmail
      contactPhone
      address
      addressId
      size
      rent
      condition
      moveIn
      furnished
      termsAccepted
      files {
        id
        filename
      }
    }
   }`,

  // =========== /RENT CHECK =========== //

  // =========== AUTHENTICATION =========== //
  auth: `query auth($username: String!, $password: String!) {
    auth(username: $username, password: $password) {
      name
      email
      role
      token
    }
  }`,
  me: `query getSession{
    me{
      name
      email
      role
      token
    }
  }`,
  // =========== /AUTHENTICATION =========== //

  // =========== CASES =========== //

  getCase: `query get($id: ID!){
    getCase(id: $id){
      id
      caseOfficer
      caseType
      # caseSubject
      caseTags
      caseDescription
      status
      address
      addressId
      rentWithoutExpenses
      leaseSize
      moveInDate
      moveOutDate
      kitchenConditon
      bathroomCondtion
      dialogueWithLandlord
      leaseType
      missingListWhenMovingIn
      moreThanOneApartment
      moveInCheckDate
      moveOutCheckDate
      moveInReportDate
      moveOutReportDate
      draft
      name
      email
      phone
      unitType # is housingType
      files {
        id
        filename
      }
    }
  }`,
  createCaseDraft: `mutation create($input: CaseInput!) {
    createCaseDraft(input: $input) {
      status
      caseSubject
      caseTags
      caseDescription
      caseType
      address
      addressId
      rentWithoutExpenses
      leaseSize
      moveInDate
      moveOutDate
      kitchenConditon
      bathroomCondtion
      dialogueWithLandlord
      leaseType
      missingListWhenMovingIn
      moreThanOneApartment
      moveInCheckDate
      moveOutCheckDate
      moveInReportDate
      moveOutReportDate
      draft
      name
      email
      phone
      unitType # is housingType
      files {
        id
        filename
      }
    }
  }`,
  updateCaseDraft: `mutation update($uuid: String!, $input: CaseInput!) {
    updateCaseDraft(uuid: $uuid, input: $input){
      status
      caseSubject
      caseTags
      caseDescription
      caseType
      address
      addressId
      rentWithoutExpenses
      leaseSize
      moveInDate
      moveOutDate
      kitchenConditon
      bathroomCondtion
      dialogueWithLandlord
      leaseType
      missingListWhenMovingIn
      moreThanOneApartment
      moveInCheckDate
      moveOutCheckDate
      moveInReportDate
      moveOutReportDate
      draft
      name
      email
      phone
      unitType # is housingType
      files {
        id
        filename
      }
    }
   }`,

  // =========== /CASES =========== //

  // =========== DASHBOARD =========== //

  dashboard: `query dashboard($status: String) {
    #listRentCheck{
    #  id
    #  contactName
    #  contactEmail
    #  contactPhone
    #  address
    #  size
    #}
    #getStats{
    # totalCases
    # totalRentCheck
    # totalSubjectAccess
    #}
    listCaseDashboard(status: $status){
     id
     caseType
     caseTags
     status
     createdAt
    }

  }`,

  // =========== /DASHBOARD =========== //

  // =========== USERS =========== //

  createUser: `mutation create($input: UserInput) {
    createUser(input: $input) {
      id
      name
      username
      email
      role
    }
  }`,

  getUser: `query get($id: ID!) {
    getUser(id: $id) {
      name
      username
      email
      role
    }
  }`,

  updateUser: `mutation update($id: ID!, $input: UserInput!) {
     updateUser(id: $id, input: $input){
      id
    # username
      email
    # phone
      name
    # lastName
    # city
    # zipCode
    # aboutMe
}  }`

  // =========== /USERS =========== //
}

// const typeDef_STATS = `
// type Stats {
//   totalCases: Int,
//   totalCasesLastMounth: Int,
//   totalRentCheck: Int,
//   totalRentCheckLastMounth: Int,
//   totalSubjectAccess: Int,
//   totalSubjectAccessLastMounth: Int,
//   casePerDay: [Int]!,
// }
// extend type Query {
//   getStats: Stats
// }
// `;

// const typeDef_USER = `
// type User {
//   username: String
//   email: String!
//   phone: Int
//   name: String!
//   lastName: String
//   city: String
//   zipCode: Int
//   about: String
//   caseOfficer: String!
// }
//
// input UserInput {
//   username: String
//   email: String!
//   phone: Int
//   name: String!
//   lastName: String
//   city: String
//   zipCode: Int
//   about: String
//   caseOfficer: String!
// }
//
// extend type Query {
//   getUser: (id: ID!): User
// }
//
// extend type Mutation {
//   updateUser: (id: ID!, input: UserInput): User
// }
// `;
