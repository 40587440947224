import React from 'react'

import './index.modules.css'

const Spinner = props => {
  return (
    <div className="loader">
      <div className="loader-outter" />
      <div className="loader-inner" />
    </div>
  )
}

export default Spinner
