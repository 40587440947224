import React from 'react'
import { Link } from '@reach/router'
import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Table from 'components/Table/Table'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import FontAwesomeIcon, {
  faFilePlus,
  faCheckCircle,
  faExclamationCircle
} from 'FontAwesome'
import Spinner from 'components/UI/Spinner'
// import Pagination from 'components/Pagination/Pagination';
// import style from "assets/jss/material-kit-pro-react/components/tableStyle.jsx";
// import basicsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx";

const styles = {
  justifyContentCenter: {
    display: 'flex',
    justifyContent: 'center!important'
  },
  buttonStyle: {
    backgroundColor: '#1C73C5',
    boxShadow:
      '0 2px 2px 0 rgba(28, 115, 197, 0.14), 0 3px 1px -2px rgba(28, 115, 197, 0.2), 0 1px 5px 0 rgba(28, 115, 197, 0.12)',
    '&:hover,&:focus': {
      backgroundColor: '#1C73C5',
      boxShadow:
        '0 14px 26px -12px rgba(28, 115, 197, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(28, 115, 197, 0.2)'
    }
  }
}

function deserializeValue(key, value) {
  switch (key) {
    case 'status':
      switch (value) {
        case 'NON_TOUCHED':
          return 'Uberørt'
        case 'TOUCHED':
          return 'Rørt'
        case 'PROCESSING':
          return 'Forarbejdet'
        case 'PENDING':
          return 'Uafgjort'
        case 'COMPLETED':
          return 'Afsluttet'
        default:
          break
      }
      break
    case 'caseType':
      switch (value) {
        case 'RENT':
          return 'Husleje'
        case 'UTILITIES':
          return 'Forbrug'
        case 'VACATING':
          return 'Fraflytning'
        case 'DEFECTS':
          return 'Mangler'
        case 'OTHERS':
          return 'Øvrige'
        default:
          break
      }
      break
    case 'draft':
      return (
        <FontAwesomeIcon
          icon={value === false ? faCheckCircle : faExclamationCircle}
          color={value === false ? 'green' : 'orange'}
        />
      )
    case 'createdAt':
      return new Date(value).toLocaleString()
    default:
      return value
  }
  return value
}

const TableList = props => {
  const {
    KEYS,
    tableHead,
    tableData,
    isLoading,
    linkTo,
    addNewButton,
    cardHead,
    classes,
    onLoadMore,
    // onPrevious,
    pageInfo
  } = props
  if (isLoading === true) {
    return <Spinner />
  }
  let data = []

  if (onLoadMore) {
    data = tableData.map(item =>
      KEYS.map(key => {
        const value = item.node[key]
        return deserializeValue(key, value)
      })
    )
  } else {
    data = tableData.map(item =>
      KEYS.map(key => {
        const value = item[key]
        return deserializeValue(key, value)
      })
    )
  }

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          {cardHead ? (
            <CardHeader color="primary">
              <h4 className={classes.cardTitle}>{cardHead.headline}</h4>
              <p className={classes.cardCategory}>{cardHead.headtext}</p>
            </CardHeader>
          ) : null}
          <CardBody>
            {addNewButton ? (
              <Link to={`${linkTo}/new`}>
                <Button color="success">
                  <FontAwesomeIcon icon={faFilePlus} size="lg" fixedWidth />
                  Opret
                </Button>
              </Link>
            ) : null}
            <Table
              tableHeaderColor="primary"
              tableHead={tableHead}
              tableData={data}
              hover
              linkTo={linkTo}
            />
          </CardBody>
          {pageInfo ? (
            <div className={classes.justifyContentCenter}>
              {/* .hasPreviousPage ? (
                <Button onClick={onPrevious} className={classes.buttonStyle}>
                  Forrige
                </Button>
              ) : null} */}
              {pageInfo.hasNextPage ? (
                <Button onClick={onLoadMore} className={classes.buttonStyle}>
                  Flere
                </Button>
              ) : null}

              {/* <Pagination
                pages={[
                
                    text: pagination.hasPreviousPage ? 'Tilbage' : '',
                    onClick: onPrevious
                  },
                  pagination.hasNextPage
                    ? {
                        text: 'Frem',
                        onClick: onNext
                      }
                    : {}
                ]}
              /> */}
            </div>
          ) : null}
        </Card>
      </GridItem>
    </GridContainer>
  )
}

export default withStyles(styles)(TableList)
