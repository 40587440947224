import React from 'react'
import '../../../assets/scss/material-kit-pro-react.scss'
// import withStyles from "@material-ui/core/styles/withStyles";
import TagsInput from 'react-tagsinput'
import moment from 'moment'
import Datetime from 'react-datetime'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import Check from '@material-ui/icons/Check'
// import Spinner from 'components/UI/Spinner'
import FontAwesomeIcon from 'FontAwesome'

const InputField = props => {
  const {
    values,
    disabled,
    onDateChange,
    onChange,
    classes,
    fields,
    onTagChange
  } = props
  // if (values.size > 0) {
  const formFields = fields().map((f, key) => {
    const { id, label, type, selectLabel } = f.field
    switch (type) {
      case 'tags':
        let tags = []
        if (values.get(id) !== undefined) {
          tags = values.get(id).toJS()
        }
        return (
          <GridItem key={id} {...f.grid} className="tags">
            <TagsInput
              disabled={disabled}
              value={tags}
              onChange={value => onTagChange(value)}
              tagProps={{
                className: 'react-tagsinput-tag primary'
              }}
              inputProps={{
                className: 'react-tagsinput-input',
                placeholder: !disabled ? 'Tilføj tag' : undefined
              }}
            />
          </GridItem>
        )
      case 'date':
        return (
          <GridItem key={id} {...f.grid}>
            <InputLabel className="labelColor">{f.field.label}</InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                onChange={date => {
                  onDateChange(date, id)
                }}
                value={
                  values.get(id) === null || values.get(id) === undefined
                    ? ''
                    : moment(values.get(id)).format('DD MMMM YYYY')
                }
                timeFormat={false}
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                inputProps={{
                  placeholder: 'Vælge dato her',
                  name: id,
                  id: id,
                  disabled: disabled
                  // type: f.field.type
                }}
              />
            </FormControl>
          </GridItem>
        )
      case 'select':
        return (
          <GridItem key={id} {...f.grid}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor={id} className={classes.selectLabel}>
                {label}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={values.get(id) || ''}
                onChange={props.onChange}
                inputProps={{
                  name: id,
                  id: id,
                  disabled: disabled
                }}
              >
                {f.field.values.map((value, key) => {
                  return (
                    <MenuItem
                      key={value}
                      classes={{
                        root: props.classes.selectMenuItem,
                        selected: props.classes.selectMenuItemSelected
                      }}
                      value={value}
                    >
                      {selectLabel[key]}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </GridItem>
        )
      case 'checkbox':
        return (
          <GridItem key={id} {...f.grid}>
            <div className={props.classes.checkboxAndRadio}>
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    type={type}
                    name={id}
                    onChange={onChange}
                    checked={values.get(id) === true ? true : false}
                    checkedIcon={<Check className={classes.checkedIcon} />}
                    icon={<Check className={classes.uncheckedIcon} />}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                classes={{
                  label: classes.label
                }}
                label={label}
              />
            </div>
          </GridItem>
        )
      case 'textarea':
        return (
          <GridItem key={id} {...f.grid}>
            <CustomInput
              labelText={label}
              id={id}
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                name: id,
                onChange: onChange,
                disabled: disabled,
                value: values.get(id) || '',
                multiline: true,
                rows: f.rows
              }}
            />
          </GridItem>
        )
      default:
        return (
          <GridItem key={f.field.id} {...f.grid}>
            <CustomInput
              labelText={f.field.label}
              id={f.field.id}
              inputProps={{
                onChange: onChange,
                name: f.field.id,
                value: values.get(id) || '',
                type: f.field.type,
                disabled: disabled,
                startAdornment:
                  f.startAdornment !== undefined ? (
                    <InputAdornment position="start">
                      <FontAwesomeIcon
                        icon={f.startAdornment.icon}
                        size="lg"
                        fixedWidth
                        // color={disabled ? "#aaa" : "#000"}
                      />
                    </InputAdornment>
                  ) : (
                    undefined
                  ),
                endAdornment:
                  f.endAdornment !== undefined ? (
                    <InputAdornment style={{ color: '#aaa' }} position="end">
                      <span>{f.endAdornment.text}</span>
                    </InputAdornment>
                  ) : (
                    undefined
                  )
              }}
              formControlProps={{ fullWidth: true }}
            />
          </GridItem>
        )
    }
  })
  return <GridContainer>{formFields}</GridContainer>
  // }
  // return <Spinner />;
}

export default InputField
