import React, { Component } from 'react'
// import { fromJS } from "immutable";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// core components
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardAvatar from 'components/Card/CardAvatar.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'

import FormBuilder from 'components/UI/FormBuilder/'
import Spinner from 'components/UI/Spinner/'
import { fields } from './Form/fieldBuilder'
import { apiCall } from '../../api'

// const styles = {
//   cardCategoryWhite: {
//     color: "rgba(255,255,255,.62)",
//     margin: "0",
//     fontSize: "14px",
//     marginTop: "0",
//     marginBottom: "0"
//   },
//   cardTitleWhite: {
//     color: "#FFFFFF",
//     marginTop: "0px",
//     minHeight: "auto",
//     fontWeight: "300",
//     fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
//     marginBottom: "3px",
//     textDecoration: "none"
//   }
// };

class User extends Component {
  state = {
    user: this.props.user,
    isEditMode: false,
    isLoading: false
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user !== this.props.user) {
      this.setState({ user: nextProps.user, isLoading: false })
    }
    if (nextProps.user.size > 0) {
      this.setState({ isLoading: false })
    }
  }

  onChangeHandler(event) {
    const { name, value } = event.target
    this.setState({ user: this.state.user.set(name, value) })
  }

  async onSubmitHandler() {
    // this.setState({ isEditMode: false });
    this.setState({ isLoading: true })
    const { id: _, ...input } = this.state.user.toJS()
    await apiCall('updateUser', {
      id: this.props.id, // NOTE: find out what the best userID is for a user. (email? username? id?)
      input: input
    })

    this.setState({
      isLoading: false,
      isEditMode: false
    })
  }

  render() {
    const { classes, userImg } = this.props
    const { user } = this.state
    if (this.state.isLoading === true || this.props.user.size <= 0) {
      return <Spinner />
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card profile>
              <CardAvatar profile>
                <a href="#pablo" onClick={e => e.preventDefault()}>
                  <img src={userImg} alt="..." />
                </a>
              </CardAvatar>
              <CardBody profile>
                <h6 className={classes.cardCategory}>{user.get('role')}</h6>
                <h4 className={classes.cardTitle}>{`${user.get('name')}
                  ${
                    this.state.user.get('lastName') !== undefined
                      ? this.state.user.get('initials') === undefined
                        ? user.get('lastName')
                        : `${user.get('lastName')}, `
                      : ''
                  }
                    ${
                      this.state.user.get('initials') !== undefined
                        ? `${user.get('initials')}`
                        : ''
                    }`}</h4>
                <p className={classes.cardTitle}>{user.get('email')}</p>
                <p className={classes.description}>{user.get('aboutMe')}</p>
                <Button
                  onClick={() => {
                    const isEditMode = this.state.isEditMode
                    this.setState({ isEditMode: !isEditMode })
                  }}
                  color="primary"
                  round
                >
                  {this.state.isEditMode
                    ? 'Se færre oplysninger'
                    : 'Se flere oplysninger'}
                </Button>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
        {/* <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
        {this.state.isEditMode === true ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Din Profil</h4>
                  {/* <p className={classes.cardCategoryWhite}>Complete your profile</p> */}
                </CardHeader>
                <CardBody>
                  <FormBuilder
                    fields={fields}
                    values={this.state.user}
                    // disabled={!isEditMode}
                    classes={classes}
                    onChange={event => this.onChangeHandler(event)}
                  />
                </CardBody>
                <CardFooter>
                  <Button
                    onClick={() => this.onSubmitHandler()}
                    color="primary"
                  >
                    Gem
                  </Button>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        ) : null}
      </div>
    )
  }
}

export default withStyles({})(User)
