import { fieldData } from './fieldData'

import {
  faUserHardHat,
  faUser,
  faEnvelope,
  faMobileAlt,
  faHome,
  faIdBadge,
  faMoneyBillWaveAlt,
  faRulerCombined,
  faWrench
} from 'FontAwesome'

export function fields() {
  return [
    {
      field: fieldData.CASE_OFFICER,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faUserHardHat }
    },
    {
      field: fieldData.STATUS,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.CASE_TYPE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
      // startAdornment: { icon:  }
    },
    {
      field: fieldData.CASE_TAGS,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
      // startAdornment: { icon:  }
    },
    {
      field: fieldData.CASE_DESCRIPTION,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      rows: 5
    },
    {
      field: fieldData.NAME,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faUser }
    },
    {
      field: fieldData.EMAIL,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faEnvelope }
    },
    {
      field: fieldData.PHONE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMobileAlt }
    },
    {
      field: fieldData.ADDRESS,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faHome }
    },
    {
      field: fieldData.ADDRESS_ID,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faIdBadge }
    },
    {
      field: fieldData.RENT_WITHOUT_EXPENSES,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faMoneyBillWaveAlt }
    },
    {
      field: fieldData.LEASE_TYPE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 }
    },
    {
      field: fieldData.LEASE_SIZE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      startAdornment: { icon: faRulerCombined },
      endAdornment: { text: 'm2' }
    },
    {
      field: fieldData.UNIT_TYPE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      startAdornment: { icon: faHome }
    },
    {
      field: fieldData.KITCHEN_CONDITON,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.BATHROOM_CONDITON,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.DIALOGUE_WITH_LANDLORD,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 }
    },
    {
      field: fieldData.MISSING_LIST_WHEN_MOVING_IN,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 }
    },
    {
      field: fieldData.MORE_THAN_ONE_ARPARTMENT,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 }
    },
    {
      field: fieldData.MOVE_IN_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.MOVE_OUT_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.MOVE_IN_CHECK_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.MOVE_OUT_CHECK_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.MOVE_IN_REPORT_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.MOVE_OUT_REPORT_DATE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    }
  ]
}
// export function fields(subCategorie) {
//   switch (subCategorie) {
//     case "CASE1":
//     case "CASE2":
//       // case "CASE3":
//       return [
//         {
//           field: fieldData.ADDRESS,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.RENT_WITHOUT_EXPENSES,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.LEASE_SIZE,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_IN_DATE,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.KITCHEN_CONDITION,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.BATH_CONDITION,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         }
//       ];
//     case "CASE4":
//     case "CASE5":
//     case "CASE6":
//     case "CASE7":
//       // case "CASE8":
//       return [
//         {
//           field: fieldData.ADDRESS,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.MOVE_IN_DATE,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_OUT_DATE,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.DIALOGUE_WITH_LANDLORD,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         }
//       ];
//     case "CASE9":
//     case "CASE10":
//     case "CASE11":
//     case "CASE12":
//       // case "CASE13":
//       return [
//         {
//           field: fieldData.ADDRESS,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.MOVE_IN_DATE,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_OUT_DATE,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.DIALOGUE_WITH_LANDLORD,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.LEASE_TYPE,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.MISSING_LIST_WHEN_MOVING_IN,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MORE_THAN_ONE_ARPARTMENT,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_IN_CHECK,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_IN_REPORT,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_OUT_CHECK,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_OUT_REPORT,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         }
//       ];
//     case "CASE14":
//     case "CASE15":
//     case "CASE16":
//       // case "CASE17":
//       return [
//         {
//           field: fieldData.ADDRESS,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.MOVE_IN_DATE,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.DIALOGUE_WITH_LANDLORD,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.LEASE_TYPE,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         },
//         {
//           field: fieldData.MOVE_IN_CHECK,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MOVE_IN_REPORT,
//           grid: { xs: 12, sm: 6, md: 6, lg: 6 }
//         },
//         {
//           field: fieldData.MISSING_LIST_WHEN_MOVING_IN,
//           grid: { xs: 12, sm: 12, md: 12, lg: 12 }
//         }
//       ];
//
//     default:
//       return [];
//   }
// }

// [
//   { ..., field: 'ADDRESS', formControlProps: { fullWidth } },
//   {grid: { xs: 12, sm: 6, md: 6, lg: 6 }, field: 'ADDRESS' }
//
// ]
