import moment from 'moment'
import 'moment/locale/da'

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  return JSON.parse(window.atob(base64))
}

export function setToken(token) {
  return localStorage.setItem('token', token)
}

export function clearToken() {
  return localStorage.removeItem('token')
}

export function getToken() {
  return localStorage.getItem('token')
}

export async function parseResponse(response) {
  if (response.status >= 200 && response.status < 300) {
    return await response.json()
  } else {
    const error = new Error(response.statusText)
    // error.response = response;
    throw error
  }
}

export function calcPercents(originalAmount, newAmount) {
  if (originalAmount < newAmount) {
    return Math.floor((originalAmount / newAmount) * 100)
  }
  return Math.floor((newAmount / originalAmount) * 100)
}

export function calcTimeSince(createDate) {
  return moment(createDate).fromNow()
}

export function mergeGraphConnection(namespace) {
  return (prev, { fetchMoreResult: next }) => {
    const newEdges = next[namespace].edges
    const pageInfo = next[namespace].pageInfo
    return {
      ...prev,
      [namespace]: {
        ...prev[namespace],
        edges: [...prev[namespace].edges, ...newEdges],
        pageInfo
      }
    }
  }
}
