import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "@reach/router";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import Icon from "@material-ui/core/Icon";
// core components
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import sidebarStyle from "assets/jss/material-dashboard-react/components/sidebarStyle.jsx";

import FontAwesomeIcon from "../../FontAwesome";

const Sidebar = ({ ...props }) => {
  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return props.location.pathname.indexOf(routeName) > -1 ? true : false;
  }
  const {
    classes,
    color,
    logo,
    backgroundImage,
    logoText,
    routes,
    username,
    userImg
  } = props;
  let links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) return null;

        let listItemClasses;
        listItemClasses = classNames({
          [" " + classes[color]]: activeRoute(prop.path)
        });
        const whiteFontClasses = classNames({
          [" " + classes.whiteFont]: activeRoute(prop.path)
        });

        return (
          <Link
            to={prop.path}
            className={classes.item}
            key={key}
            onClick={prop.onClick}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                <FontAwesomeIcon icon={prop.icon} size="lg" fixedWidth />
              </ListItemIcon>
              <ListItemText
                primary={prop.sidebarName}
                className={classes.itemText + whiteFontClasses}
                disableTypography={true}
              />
            </ListItem>
          </Link>
        );
      })}
    </List>
  );
  const brand = (
    <div className={classes.logo}>
      <Link to="/" className={classes.logoLink}>
        <div className={classes.logoImage}>
          <img src={logo} alt="logo" className={classes.img} />
        </div>
        <span className="logoText">{logoText}</span>
      </Link>
    </div>
  );
  const profile = (
    <div className={classes.profil}>
      <Link to="/profile" className={classes.profilLink}>
        <div className={classes.profilImage}>
          <img src={userImg} alt="profile" className={classes.profilImg} />
        </div>
        <span className="username">{username}</span>
      </Link>
    </div>
  );
  return (
    <div>
      {/* NOTE: Gives a test error, because there are 2 hidden*/}
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}
          {profile}
          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            {links}
          </div>
          {backgroundImage !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + backgroundImage + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: classes.drawerPaper
          }}
        >
          {brand}

          {profile}

          <div className={classes.sidebarWrapper}>{links}</div>
          {backgroundImage !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: "url(" + backgroundImage + ")" }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(sidebarStyle)(Sidebar);
