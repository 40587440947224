import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import Spinner from 'components/UI/Spinner/'
import Stats from './Stats'
import CasesChart from './CasesChart'
import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle'

import { queryCreate } from 'api'
import { calcTimeSince } from 'helper'

import TableList from 'components/UI/TableList/'

const IN_DEV = 'development' // NOTE: just for development

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      cases: {
        KEYS: ['id', 'caseType', 'caseTags', 'status', 'createdAt'],
        data: []
      }
    }
  }
  componentDidMount() {
    this.setState({ isLoading: true })
    queryCreate('dashboard', { status: 'NON_TOUCHED' }).then(res => {
      const { data, errors } = res

      if (errors) {
        this.setState({ error: errors[0].message, isLoading: false })
      } else {
        this.setState({
          isLoading: false,
          cases: {
            ...this.state.cases,
            data: data.listCaseDashboard.map(item =>
              this.state.cases.KEYS.map(key => {
                switch (key) {
                  case 'caseTags':
                    return item[key].join(', ')
                  case 'createdAt':
                    return calcTimeSince(item[key])
                  case 'status':
                    switch (item[key]) {
                      case 'NON_TOUCHED':
                        return 'Uberørt'
                      case 'TOUCHED':
                        return 'Rørt'
                      case 'PROCESSING':
                        return 'Forarbejdet'
                      case 'PENDING':
                        return 'Uafgjort'
                      case 'COMPLETED':
                        return 'Afsluttet'
                      default:
                        break
                    }
                    break
                  case 'caseType':
                    switch (item[key]) {
                      case 'RENT':
                        return 'Husleje'
                      case 'UTILITIES':
                        return 'Forbrug'
                      case 'VACATING':
                        return 'Fraflytning'
                      case 'DEFECTS':
                        return 'Mangler'
                      case 'OTHERS':
                        return 'Øvrige'
                      default:
                        break
                    }
                    break
                  default:
                    return item[key]
                }
                return item[key]
              })
            )
          }
        })
      }
    })
  }
  render() {
    const { classes } = this.props
    if (this.state.isLoading === true) {
      return <Spinner />
    } else if (this.state.error) {
      return <h2>{this.state.error}</h2>
    }
    return (
      <div>
        {process.env.NODE_ENV === IN_DEV ? <Stats classes={classes} /> : null}
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {process.env.NODE_ENV === IN_DEV ? (
              <TableList
                cardHead={{
                  headline: 'Dine sager DUMMY DATA'
                }}
                tableHead={[
                  'Sagstype',
                  'Status',
                  'Kategori',
                  'Tid siden berøring'
                ]}
                tableData={[]}
                linkTo={''}
              />
            ) : null}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TableList
              cardHead={{
                headline: 'Sager',
                headtext: 'De nyeste sager'
              }}
              tableHead={[
                'Sagstype',
                'Kategori',
                'status',
                'Tid siden oprettet'
              ]}
              tableData={[]}
              // tableData={this.state.cases.data}
              linkTo={'/cases'}
            />
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={12}>
            {process.env.NODE_ENV === IN_DEV ? (
              <CasesChart classes={classes} />
            ) : null}
          </GridItem>
        </GridContainer>
      </div>
    )
  }
}

export default withStyles(dashboardStyle)(Dashboard)
