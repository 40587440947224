import React from 'react'
import TableList from 'components/UI/TableList'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'
import { mergeGraphConnection } from 'helper'

const KEYS = ['id', 'contactName', 'contactEmail', 'address', 'size']
const TABLE_HEAD = ['Navn', 'Email', 'Adresse', 'Størrelse']
const GET_ALL_RENT_CHECK = gql`
  query list($cursor: String) {
    listRentChecks(first: 25, after: $cursor) {
      edges {
        cursor
        node {
          id
          contactName
          contactEmail
          address
          size
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

const AllRentCheck = props => {
  const { path } = props

  return (
    <Query query={GET_ALL_RENT_CHECK}>
      {({ loading, error, data: { listRentChecks = {} }, fetchMore }) => {
        if (error) {
          return (
            <h2>
              {/* // TODO: replace hack to remove server name */}
              {error.message.replace('GraphQL error: ', '')}
            </h2>
          )
        }
        return (
          <TableList
            KEYS={KEYS}
            // addNewButton
            tableHead={TABLE_HEAD}
            tableData={listRentChecks.edges}
            // tableData={[]}
            isLoading={loading}
            linkTo={path}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  cursor: listRentChecks.pageInfo.endCursor
                },
                updateQuery: mergeGraphConnection('listRentCheck')
              })
            }
            // onPrevious={() =>
            //   fetchMore({
            //     variables: { cursor: listRentCheck.pageInfo.startCursor },
            //     updateQuery: (previousResult, { fetchMoreResult }) => {
            //       const newEdges = fetchMoreResult.listRentCheck.edges;
            //       const pageInfo = fetchMoreResult.listRentCheck.pageInfo;
            //       console.log(
            //         'start cursor',
            //         listRentCheck.pageInfo.startCursor
            //       );
            //       return newEdges.length
            //         ? {
            //             listRentCheck: {
            //               __typename: previousResult.listRentCheck.__typename,
            //               edges: [...newEdges],
            //               pageInfo
            //             }
            //           }
            //         : previousResult;
            //     }
            //   })
            // }
            pageInfo={listRentChecks.pageInfo}
          />
        )
      }}
    </Query>
    // <ListView
    //   path={path}
    //   KEYS={KEYS}
    //   TABLE_HEAD={TABLE_HEAD}
    //   query={GET_ALL_RENT_CHECK}
    // />
  )
}

export default AllRentCheck
