// @flow
export const fieldData = {
  CONTACT_NAME: {
    label: 'Navn',
    id: 'contactName',
    type: 'text'
  },
  CONTACT_EMAIL: {
    label: 'Email',
    id: 'contactEmail',
    type: 'email'
  },
  CONTACT_PHONE: {
    label: 'Telefon',
    id: 'contactPhone',
    type: 'tel'
  },
  ADDRESS: {
    label: 'Adresse',
    id: 'address',
    type: 'text'
  },
  ADDRESS_ID: {
    label: 'Adresse ID',
    id: 'addressId',
    type: 'text'
  },
  BBR_SIZE: {
    label: 'BBR størrelse',
    id: 'bbrSize',
    type: 'number'
  },
  SIZE: {
    label: 'Størrelse',
    id: 'size',
    type: 'number'
  },
  RENT: {
    label: 'Husleje',
    id: 'rent',
    type: 'number'
  },
  CONDITION: {
    label: 'Lejemålets stand',
    id: 'condition',
    type: 'number'
  },
  MOVE_IN: {
    label: 'Indflytningsdato',
    id: 'moveIn',
    type: 'date'
  },
  FURNISHED: {
    label: 'Møbleret lejemål',
    id: 'furnished',
    type: 'checkbox'
  }
}
