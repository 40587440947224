import React from 'react'
import '../../assets/css/customStyles.css'

const NotFound = () => {
  return (
    <div className="wrapper">
      <h1 className="status">404</h1>
      <img
        className="statusImg"
        src="https://api.adorable.io/avatars/285/2"
        alt=""
      />
      <p className="subStatus">The page does not exist</p>
    </div>
  )
}

export default NotFound
