// @flow
export const fieldData = {
  USERNAME: {
    label: 'Brugernavn',
    id: 'username',
    type: 'text'
  },
  EMAIL: {
    label: 'Email',
    id: 'email',
    type: 'email'
  },
  PHONE: {
    label: 'Telefon nummber',
    id: 'phone',
    type: 'tel'
  },
  FIRST_NAME: {
    label: 'Forenavn',
    id: 'name',
    type: 'text'
  },
  LAST_NAME: {
    label: 'Efternavn',
    id: 'lastName',
    type: 'text'
  },
  INITIALS: {
    label: 'Initialer',
    id: 'initials',
    type: 'text'
  },
  CITY: {
    label: 'By',
    id: 'city',
    type: 'text'
  },
  COUNTRY: {
    label: 'Land',
    id: 'country',
    type: 'text'
  },
  ZIP_CODE: {
    label: 'Post nummer',
    id: 'zipCode',
    type: 'text'
  },
  ABOUT_ME: {
    label: 'Om mig',
    id: 'aboutMe',
    type: 'textarea'
  }
}
