// @flow
import { fieldData } from './fieldData'

import {
  faHome,
  faRulerCombined,
  faDoorClosed,
  faPencilRuler,
  faCogs,
  faMoneyBillWaveAlt,
  faWrench,
  // faEdit,
  // faLongArrowLeft,
  // faBan,
  // faSave,
  // faPlus,
  faCity,
  faSuitcase,
  faFileAlt
} from '../../../FontAwesome'

export const fields = () => {
  return [
    {
      field: fieldData.CASE_REF,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faSuitcase }
    },
    {
      field: fieldData.CASE_TYPE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 }
    },
    {
      field: fieldData.CASE_SUBJECT,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faFileAlt }
    },
    {
      field: fieldData.CASE_TOWN,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faCity }
    },
    {
      field: fieldData.ADDRESS,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faHome }
    },
    {
      field: fieldData.SIZE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faRulerCombined },
      endAdornment: { text: 'm2' }
    },
    {
      field: fieldData.ROOMS,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faDoorClosed },
      endAdornment: { text: 'værelser' }
    },
    {
      field: fieldData.COMPLETION_YEAR,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faPencilRuler },
      endAdornment: { text: 'år' }
    },
    {
      field: fieldData.MODERNIZATION_YEAR,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faCogs },
      endAdornment: { text: 'år' }
    },
    {
      field: fieldData.MODERNIZATION_AMOUNT,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faMoneyBillWaveAlt },
      endAdornment: { text: 'kr,-' }
    },
    {
      field: fieldData.CODITION_KITCHEN_SCORE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.CODITION_BATH_SCORE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.CODITION_OTHER_SCORE,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.CODITION_KITCHEN_COMMENT,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      rows: 2
    },
    {
      field: fieldData.CODITION_BATH_COMMENT,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      rows: 2
    },
    {
      field: fieldData.CODITION_OTHER_COMMENT,
      grid: { xs: 12, sm: 4, md: 4, lg: 4 },
      rows: 2
    },
    {
      field: fieldData.RENT_DECISION_BEFORE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMoneyBillWaveAlt },
      endAdornment: { text: 'kr,-' }
    },
    {
      field: fieldData.RENT_DECISION_AFTER,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMoneyBillWaveAlt },
      endAdornment: { text: 'kr,-' }
    },
    {
      field: fieldData.DEPOSIT,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMoneyBillWaveAlt },
      endAdornment: { text: 'kr,-' }
    },
    {
      field: fieldData.PREPAID,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMoneyBillWaveAlt },
      endAdornment: { text: 'kr,-' }
    },
    {
      field: fieldData.CASE_FAVOR,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 }
    },
    {
      field: fieldData.COMMENT,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      rows: 5
    }
  ]
}
