import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import ActionConfirmModal from './ActionConfirmModal'

import { apiFetch } from '../../api'

class ActionButtonLegis365 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      error: null,
      status: null
    }

    this.triggerAction = this.triggerAction.bind(this)
  }

  async triggerAction() {
    this.setState({ loading: true })
    const res = await apiFetch({
      query: `mutation triggerAction($id: ID!, $action: CaseAction!, $force: Boolean) {
          triggerCaseAction(id: $id, action: $action, force: $force)
      }`,
      variables: { id: this.props.id, action: 'LEGIS365' }
    })
    const status = (res.data && res.data.triggerCaseAction) || false
    this.setState({
      loading: false,
      status: status,
      error:
        res.errors || status === false
          ? 'Something went wrong, please try again'
          : null
    })
  }

  render() {
    if (!this.props.id) {
      return null
    }

    const submitted = this.state.status === true

    return (
      <>
        <ActionConfirmModal
          onConfirm={this.triggerAction}
          passphrase={(this.props.passphrase || 'legis365')
            .split(' ')[0]
            .trim()
            .toLowerCase()}
        >
          {({ open }) => (
            <Button
              disabled={this.state.loading || submitted}
              variant="contained"
              color="primary"
              onClick={open}
            >
              {this.state.loading
                ? 'Please wait...'
                : submitted
                ? 'Sagen er sendt til Legis365'
                : this.props.children}
            </Button>
          )}
        </ActionConfirmModal>
        <br />
        {this.state.error && (
          <span style={{ color: 'red', fontSize: '0.8em' }}>
            {this.state.error}
          </span>
        )}
      </>
    )
  }
}

export default ActionButtonLegis365
