// @flow
export const fieldData = {
  CASE_OFFICER: {
    label: 'Case officer',
    id: 'caseOfficer',
    type: 'text'
  },
  CASE_TYPE: {
    label: 'Sagstype',
    values: ['RENT', 'UTILITIES', 'VACATING', 'DEFECTS', 'OTHERS'],
    selectLabel: ['Husleje', 'Forbrug', 'Fraflytning', 'Mangler', 'Øvrige'],
    id: 'caseType',
    type: 'select'
  },
  CASE_TAGS: {
    label: 'Tags',
    id: 'caseTags',
    type: 'tags'
  },
  CASE_DESCRIPTION: {
    label: 'Sagsbeskrivelse',
    id: 'caseDescription',
    type: 'textarea'
  },
  STATUS: {
    label: 'Status',
    values: ['NON_TOUCHED', 'TOUCHED', 'PROCESSING', 'PENDING', 'COMPLETED'],
    selectLabel: ['Uberørt', 'Rørt', 'Forarbejdet', 'Uafgjort', 'Afsluttet'],
    id: 'status',
    type: 'select'
  },
  ADDRESS: {
    label: 'Adresse',
    id: 'address',
    type: 'address'
  },
  ADDRESS_ID: {
    label: 'Adresse ID',
    id: 'addressId',
    type: 'text'
  },
  RENT_WITHOUT_EXPENSES: {
    label: 'Månedlig husleje (ekslusiv forbrug)',
    id: 'rentWithoutExpenses',
    type: 'number'
  },
  LEASE_SIZE: {
    label: 'Lejemålets Størrelse',
    id: 'leaseSize',
    type: 'number'
  },
  MOVE_IN_DATE: {
    label: 'Indflytningsdato',
    id: 'moveInDate',
    type: 'date'
  },
  MOVE_OUT_DATE: {
    label: 'Udflytningsdato',
    id: 'moveOutDate',
    type: 'date'
  },
  KITCHEN_CONDITON: {
    label: 'Stand af Køkken',
    values: ['WORN', 'STANDARD', 'RENOVATED'],
    selectLabel: ['Nedslidt', 'Standard', 'Nyrenoveret'],
    id: 'kitchenConditon',
    type: 'select'
  },
  BATHROOM_CONDITON: {
    label: 'Stand af badeværelse',
    values: ['WORN', 'STANDARD', 'RENOVATED'],
    selectLabel: ['Nedslidt', 'Standard', 'Nyrenoveret'],
    id: 'bathroomCondtion',
    type: 'select'
  },
  DIALOGUE_WITH_LANDLORD: {
    label: 'Dialog med udlejer',
    id: 'dialogueWithLandlord',
    type: 'checkbox'
  },
  LEASE_TYPE: {
    label: 'Hvilken type lejekontrakt er brugt',
    values: ['A9', 'A8', '1998', 'DONT_KNOW'],
    selectLabel: ['A9', 'A8', '1998', 'ved ikke'],
    id: 'leaseType',
    type: 'select'
  },
  MISSING_LIST_WHEN_MOVING_IN: {
    label: 'Mangelliste ved indflytning',
    id: 'missingListWhenMovingIn',
    type: 'checkbox'
  },
  MORE_THAN_ONE_ARPARTMENT: {
    label: 'Flere end én Beboelseslejlighed',
    id: 'moreThanOneApartment',
    type: 'checkbox'
  },
  MOVE_IN_CHECK_DATE: {
    label: 'Indflytningssyn',
    id: 'moveInCheckDate',
    type: 'date'
  },
  MOVE_OUT_CHECK_DATE: {
    label: 'Fraflytningssyn',
    id: 'moveOutCheckDate',
    type: 'date'
  },
  MOVE_IN_REPORT_DATE: {
    label: 'Indflytningsrapport',
    id: 'moveInReportDate',
    type: 'date'
  },
  MOVE_OUT_REPORT_DATE: {
    label: 'Fraflytningsrapport',
    id: 'moveOutReportDate',
    type: 'date'
  },
  NAME: {
    label: 'Navn',
    id: 'name',
    type: 'text'
  },
  EMAIL: {
    label: 'Email',
    id: 'email',
    type: 'text'
  },
  PHONE: {
    label: 'Telefonnummer',
    id: 'phone',
    type: 'text'
  },
  UNIT_TYPE: {
    label: 'Hus type',
    id: 'unitType',
    type: 'text'
  }
}
