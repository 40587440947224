import React, { Component } from 'react'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import FontAwesomeIcon, { faPaperclip } from 'FontAwesome'

class FileUploadInput extends Component {
  onFocus = event => {
    this.refs.hiddenFile.click(event)
    event.target.blur()
  }
  render() {
    const { classes, file, disabled, onChange, caseId } = this.props
    const fileId = file.get('id')
    //console.log(caseId);
    const filename = file.get('filename')
      ? decodeURI(file.get('filename'))
      : undefined

    return fileId ? (
      <a
        className="fileLink"
        href={`https://api.digura.dk/fs/${caseId}/${fileId}`}
        download
        data-tip={`https://api.digura.dk/fs/${caseId}/${fileId}`}
        data-for="image-preview"
      >
        {filename}
      </a>
    ) : !fileId && !disabled ? null : (
      <div className={classes.inputFileWrapper}>
        <>
          <input
            type="file"
            className={classes.inputFile}
            ref="hiddenFile"
            onChange={onChange}
          />
          <CustomInput
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              placeholder: 'Tilføj fil',
              onFocus: this.onFocus,
              disableUnderline: !disabled,
              disabled: !disabled,
              value: filename,
              endAdornment: disabled ? (
                !filename ? (
                  <Button
                    round
                    justIcon
                    color="primary"
                    onClick={this.onFocus}
                    // simple
                  >
                    <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                  </Button>
                ) : null
              ) : null
            }}
          />
        </>
      </div>
    )
  }
}

export default FileUploadInput
