import React, { Component } from 'react'
import { navigate } from '@reach/router'
import withStyles from '@material-ui/core/styles/withStyles'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import { fromJS } from 'immutable'
import FormBuilder from 'components/UI/FormBuilder'
import { fields } from './Form/fieldBuilder'
import Snackbar from 'components/Snackbar/Snackbar'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Spinner from 'components/UI/Spinner'
import EditButtons from 'components/UI/EditButtons/'
import GridItem from 'components/Grid/GridItem'
import { queryCreate } from 'api/'

class SingleUsers extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: Boolean(props.id),
      isEditMode: !props.id,
      formData: fromJS({}),
      notification: {
        open: false,
        color: 'primary',
        message: 'dummy'
      }
    }
  }

  componentDidMount() {
    if (this.props.id) {
      queryCreate('getUser', { id: this.props.id }).then(res => {
        this.setState({ formData: fromJS(res.data.getUser), isLoading: false })
      })
    }
  }

  closeNotification() {
    this.alertTimeout = setTimeout(
      function() {
        this.setState({
          notification: { open: false, color: 'primary', message: 'dummy' }
        })
      }.bind(this),
      3000
    )
  }

  onSubmitHandler = async event => {
    event.preventDefault()
    const { formData } = this.state
    // {===== UPDATE ======}
    if (this.props.id) {
      this.setState({ isLoading: true })
      const { id: _, files: __, ...input } = formData.toJS()
      const res = await queryCreate('updateUser', {
        id: this.props.id,
        input: input
      })
      const { data, errors } = res
      if (errors) {
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: errors[0].message
          }
        })
      } else {
        this.setState({
          isLoading: false,
          isEditMode: false,
          formData: fromJS(data.createUser),
          notification: {
            open: true,
            color: 'info',
            message: 'User er blevet gemt'
          }
        })
        this.closeNotification()
      }
    } else {
      // { ===== CREATE NEW ===== }
      this.setState({ isLoading: true })

      const res = await queryCreate('createUser', {
        input: formData.toJS()
      })
      const { data, errors } = res
      if (errors) {
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: errors[0].message
          }
        })
      } else {
        this.setState({
          isLoading: false,
          isEditMode: false,
          formData: fromJS(data.createUser),
          notification: {
            open: true,
            color: 'success',
            message: 'Bruger oprettet'
          }
        })
        await this.closeNotification()
        navigate(`/users/${data.createUser.id}`)
      }
    }
  }

  onCancelHandler = () => {
    if (this.props.id) {
      queryCreate('getUser', { id: this.props.id }).then(res => {
        this.setState({
          formData: fromJS(res.data.getUser),
          isEditMode: false
        })
      })
    } else {
      navigate('./')
    }
  }

  onChangeHandler(event) {
    const { name, value } = event.target
    this.setState({ formData: this.state.formData.set(name, value) })
  }

  render() {
    const { formData, isEditMode, isLoading } = this.state

    const { classes } = this.props
    if (isLoading) {
      return <Spinner />
    }
    return (
      <>
        <Snackbar
          place="tc"
          color={this.state.notification.color}
          // icon={faBell}
          message={this.state.notification.message}
          open={this.state.notification.open}
          closeNotification={() =>
            this.setState({ notification: { open: false } })
          }
          close
        />
        <Card>
          <form onSubmit={this.onSubmitHandler} className={classes.form}>
            <CardBody>
              <FormBuilder
                fields={fields}
                values={formData}
                disabled={!isEditMode}
                classes={classes}
                onChange={event => this.onChangeHandler(event)}
              />

              <GridItem xs={12} sm={12} md={12} lg={12}>
                <EditButtons
                  isEditMode={isEditMode}
                  id={this.props.id}
                  onCancelClick={this.onCancelHandler}
                  toggleEditMode={this.toggleEditMode}
                />
              </GridItem>
            </CardBody>
          </form>
        </Card>
      </>
    )
  }
}

export default withStyles(basicsStyle)(SingleUsers)
