import { QUERIES } from './queries'
import { getToken, parseResponse } from 'helper'

export const API_ENDPOINT =
  process.env.NODE_ENV === 'production'
    ? 'https://api.digura.dk'
    : 'http://localhost:9000'

export async function apiFetch(body) {
  const token = await getToken()
  const res = await fetch(`${API_ENDPOINT}/graph`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: token ? `Bearer ${token}` : undefined
    },
    body: JSON.stringify(body)
  })
  return await parseResponse(res)
}

export async function queryCreate(action, variables) {
  const query = QUERIES[action]
  const body = { query, variables }
  // if (apiFetch === null) {
  return await apiFetch(body)
  // }
}

export async function apiCall(action, variables) {
  const json = await queryCreate(action, variables)
  if (action === 'dashboard') {
    return await json.data
  }

  try {
    return await json.data[action]
  } catch (error) {
    return json.errors[0].message
  }
}

export async function getLogin(variables) {
  return await apiCall(variables ? 'auth' : 'me', variables)
}

export async function apiUploadFile(id, files) {
  try {
    const token = getToken()

    const formData = new FormData()
    for (let i = 0; i < files.size; i++) {
      formData.append('files', files.getIn([i, 'file'])[0])
    }
    const res = await fetch(`${API_ENDPOINT}/fs/${id}`, {
      method: 'POST',
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      },
      body: formData
    })
    const data = await res.json()
    return data
  } catch (error) {
    console.log(error)
  }
}
