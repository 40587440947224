import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { fromJS } from 'immutable'
import withStyles from '@material-ui/core/styles/withStyles'
// import GridContainer from "components/Grid/GridContainer";
import GridItem from 'components/Grid/GridItem'

import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
// import Button from "components/CustomButtons/Button.jsx";
// import FontAwesomeIcon, {
//   faBan,
//   faLongArrowLeft,
//   faPlus,
//   faSave,
//   faEdit
// } from "../../FontAwesome";
import Spinner from 'components/UI/Spinner'
import FormBuilder from 'components/UI/FormBuilder/'
import EditButtons from 'components/UI/EditButtons/'
import { fields } from './Form/fieldBuilder'
// import FileUpload from "components/UI/FileUpload/FileUpload";
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import { apiCall } from '../../api'

class SingelDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: Boolean(props.id),
      fileIsLoading: false,
      isEditMode: !props.id,
      formData: fromJS({})
    }
  }

  componentDidMount() {
    if (this.props.id) {
      apiCall('getRentCheck', { id: this.props.id }).then(data => {
        this.setState({
          formData: fromJS(data),
          isLoading: false
        })
      })
    }
  }

  toggleEditMode = () => {
    const isEditMode = this.state.isEditMode
    this.setState({ isEditMode: !isEditMode })
  }
  //
  onSubmitHandler = async event => {
    event.preventDefault()
    const { formData } = this.state

    try {
      this.setState({ isLoading: true })
      const { id: _, files: __, bbrSize: ___, ...input } = formData.toJS()
      await apiCall('updateRentCheck', {
        id: this.props.id,
        input: input
      })

      this.setState({
        isLoading: false,
        isEditMode: false
      })
    } catch (err) {
      console.log(err)
    }
  }

  onCancelHandler = () => {
    if (this.props.id) {
      apiCall('getRentCheck', { id: this.props.id }).then(data => {
        this.setState({ formData: fromJS(data), isEditMode: false })
      })
    } else {
      navigate('./')
    }
  }

  // NOTE: Kan træk this.setState ud i sin egen func
  onChangeHandler = event => {
    const { value, type, name } = event.target
    if (type === 'number') {
      this.setState({ formData: this.state.formData.set(name, Number(value)) })
    } else if (type === 'checkbox') {
      this.setState({
        formData: this.state.formData.set(name, event.target.checked)
      })
    } else {
      this.setState({ formData: this.state.formData.set(name, value) })
    }
  }

  render() {
    const { formData, isEditMode, isLoading } = this.state
    const { classes } = this.props
    if (isLoading === true) {
      return <Spinner />
    }
    return (
      <>
        <Card>
          <form onSubmit={this.onSubmitHandler} className={classes.form}>
            <CardBody>
              <FormBuilder
                fields={fields}
                values={formData}
                disabled={!isEditMode}
                classes={classes}
                onChange={this.onChangeHandler}
              />
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <EditButtons
                  isEditMode={isEditMode}
                  id={this.props.id}
                  onCancelClick={this.onCancelHandler}
                  toggleEditMode={this.toggleEditMode}
                />
              </GridItem>
            </CardBody>
          </form>
        </Card>
        {/* ===== FILE UPLOAD ==== */}
        {/* {this.props.id ? (
          <FileUpload
            isLoading={this.state.fileIsLoading}
            files={formData.get("files") || []}
            id={this.props.id}
            isEditMode={isEditMode}
            toggleEditMode={this.toggleEditMode}
          />
        ) : null} */}
      </>
    )
  }
}

export default withStyles(basicsStyle)(SingelDocument)
