export const fieldData = {
  USER_NAME: {
    id: 'name',
    label: 'Navn',
    type: 'text'
  },
  USER_USERNAME: {
    id: 'username',
    label: 'Bruger navn',
    type: 'text'
  },
  USER_EMAIL: {
    id: 'email',
    label: 'Email',
    type: 'email'
  },
  USER_ROLE: {
    id: 'role',
    values: ['client', 'caseworker', 'supervisor', 'manager'],
    selectLabel: ['Client', 'Caseworker', 'Supervisor', 'Manger'],
    label: 'Rolle',
    type: 'select'
  },
  USER_PASSWORD: {
    id: 'password',
    label: 'Password',
    type: 'password'
  }
}
