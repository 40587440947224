// ##############################
// // // javascript library for creating charts
// #############################
import Chartist from "chartist";
// ##############################
// // // variables used to create animation on charts
// #############################
const delays = 80,
  durations = 500;
// var delays2 = 80,
//   durations2 = 500;

// ##############################
// // // Daily Cases
// #############################
const data = [120, 100, 7, 50, 23, 18, 380];
export const dailyCasesChart = {
  data: {
    labels: ["Man", "Tir", "Ons", "Tors", "Fre", "Lør", "Søn"],
    series: [data]
  },
  options: {
    low: 0,
    showArea: true,
    lineSmooth: Chartist.Interpolation.cardinal({
      tension: 0
    }),
    high: Math.max.apply(null, data) + 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
    chartPadding: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
  },
  // for animation
  animation: {
    draw: function(data) {
      if (data.type === "line" || data.type === "area") {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path
              .clone()
              .scale(1, 0)
              .translate(0, data.chartRect.height())
              .stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if (data.type === "point") {
        data.element.animate({
          opacity: {
            begin: (data.index + 1) * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: "ease"
          }
        });
      }
    }
  }
};
