import React from 'react'
import TableList from 'components/UI/TableList'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const KEYS = ['id', 'name', 'email', 'role']
const TABLE_HEAD = ['Navn', 'Email', 'Rolle']
const GET_ALL_USERS = gql`
  {
    listUser {
      id
      name
      email
      role
    }
  }
`

const AllUsers = props => {
  const { path } = props

  return (
    <Query query={GET_ALL_USERS}>
      {({ loading, error, data }) => {
        if (error) {
          return (
            <h2>
              {/* NOTE: replace hack to remove server name */}
              {error.message.replace('GraphQL error: ', '')}
            </h2>
          )
        }
        return (
          <TableList
            addNewButton
            KEYS={KEYS}
            tableHead={TABLE_HEAD}
            tableData={data.listUser}
            isLoading={loading}
            linkTo={path}
          />
        )
      }}
    </Query>
  )
}

export default AllUsers
