import React from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

export default class FormDialog extends React.Component {
  state = {
    open: false,
    passphrase: ''
  }

  handleChange = ev => {
    this.setState({ passphrase: ev.target.value })
  }

  handleClickOpen = () => {
    this.setState({ open: true })
  }

  handleClose = () => {
    this.setState({ open: false })
  }

  handleConfirm = () => {
    if (this.state.passphrase.toLowerCase() === this.props.passphrase) {
      this.props.onConfirm()
      this.handleClose()
    }
  }

  render() {
    return (
      <>
        {this.props.children({
          open: this.handleClickOpen,
          close: this.handleClose
        })}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Confirm action</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Please type <code>{this.props.passphrase}</code> in the field
              below, to confirm you action.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="passphrase"
              label="Passphrase"
              type="text"
              fullWidth
              onChange={this.handleChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button
              onClick={this.handleConfirm}
              variant="contained"
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
