import React from 'react'
import TableList from '../../components/UI/TableList'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const KEYS = [
  'id',
  'caseRef',
  'caseType',
  'caseSubject',
  'caseTown',
  'caseFavor'
]
const TABLE_HEAD = [
  'Sagsnr.',
  'Sagstype',
  'Emne',
  'Huslejenævn (by)',
  'Hvem fik medhold?'
]

const GET_ALL_SUBJECT_ACCESS = gql`
  query list($cursor: String) {
    listSubjectAccessCursor(first: 10, after: $cursor) {
      edges {
        cursor
        node {
          id
          caseRef
          caseType
          caseSubject
          caseTown
          caseFavor
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
`

const AllDocument = props => {
  const { path } = props

  return (
    <Query query={GET_ALL_SUBJECT_ACCESS}>
      {({
        loading,
        error,
        data: { listSubjectAccessCursor = {} },
        fetchMore
      }) => {
        if (error) {
          return (
            <h2>
              {/* NOTE: replace() hack to remove server name */}
              {error.message.replace('GraphQL error: ', '')}
            </h2>
          )
        }
        return (
          <TableList
            addNewButton
            KEYS={KEYS}
            tableHead={TABLE_HEAD}
            tableData={listSubjectAccessCursor.edges}
            isLoading={loading}
            linkTo={path}
            onLoadMore={() =>
              fetchMore({
                variables: {
                  cursor: listSubjectAccessCursor.pageInfo.endCursor
                },
                updateQuery: (previousResult, { fetchMoreResult }) => {
                  const newEdges = fetchMoreResult.listSubjectAccessCursor.edges
                  const pageInfo =
                    fetchMoreResult.listSubjectAccessCursor.pageInfo
                  return newEdges.length
                    ? {
                        listSubjectAccessCursor: {
                          __typename:
                            previousResult.listSubjectAccessCursor.__typename,
                          edges: [
                            ...previousResult.listSubjectAccessCursor.edges,
                            ...newEdges
                          ],
                          pageInfo
                        }
                      }
                    : previousResult
                }
              })
            }
            pageInfo={listSubjectAccessCursor.pageInfo}
          />
        )
      }}
    </Query>
    // <ListView
    //   path={path}
    //   KEYS={KEYS}
    //   TABLE_HEAD={TABLE_HEAD}
    //   query={GET_ALL_SUBJECT_ACCESS}
    // />
  )
}

export default AllDocument
