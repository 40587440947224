import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { fromJS } from 'immutable'
// import moment from "moment";
// import { toast } from "react-toastify";
import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from 'components/Grid/GridItem'
import Snackbar from 'components/Snackbar/Snackbar'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Spinner from 'components/UI/Spinner'
import EditButtons from 'components/UI/EditButtons/'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import FileUpload from 'components/UI/FileUpload/FileUpload'

import FormBuilder from 'components/UI/FormBuilder'
import { fields } from './Form/fieldBuilder'
// import { faBell } from "FontAwesome";
import { apiCall } from '../../api'
import ActionButtonLegis365 from './ActionButtonLegis365'

class SingleCases extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: Boolean(props.id),
      fileIsLoading: false,
      isEditMode: !props.id,
      formData: fromJS({}),
      notification: {
        open: false,
        color: 'primary',
        message: ''
      }
    }
  }

  componentDidMount() {
    if (this.props.id) {
      apiCall('getCase', { id: this.props.id }).then(data => {
        this.setState({ formData: fromJS(data), isLoading: false })
      })
    }
  }

  closeNotification(time) {
    this.alertTimeout = setTimeout(
      function() {
        this.setState({
          notification: { ...this.state.notification, open: false }
        })
      }.bind(this),
      time
    )
  }
  toggleEditMode = () => {
    const isEditMode = this.state.isEditMode
    this.setState({ isEditMode: !isEditMode })
  }

  onSubmitHandler = async event => {
    event.preventDefault()
    const { formData } = this.state
    // {===== UPDATE ======}
    if (this.props.id) {
      try {
        this.setState({ isLoading: true })
        const { id: _, files: __, ...input } = formData.toJS()
        await apiCall('updateCaseDraft', {
          uuid: this.props.id,
          input: input
        })

        this.setState({
          isLoading: false,
          isEditMode: false,
          notification: {
            open: true,
            color: 'info',
            message: 'Sagen updatede'
          }
        })
        this.closeNotification(3000)
      } catch (err) {
        console.log(err)
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: 'Kunne ikke gemmes'
          }
        })
        this.closeNotification(3000)
      }
    } else {
      // { ===== CREATE NEW ===== }
      try {
        this.setState({ isLoading: true })
        const data = await apiCall('createCaseDraft', {
          input: formData.toJS()
        })
        await this.setState({
          isLoading: false,
          isEditMode: false,
          formData: fromJS(data),
          notification: {
            open: true,
            color: 'success',
            message: 'Sag oprettet'
          }
        })
        this.closeNotification(3000)
        navigate(`/cases/${data.id}`)
      } catch (err) {
        console.log(err)
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: 'Sag Kunne ikke oprettes'
          }
        })
        this.closeNotification(3000)
      }
      return null
    }
  }

  onCancelHandler = () => {
    if (this.props.id) {
      apiCall('getCase', { id: this.props.id }).then(data => {
        this.setState({ formData: fromJS(data), isEditMode: false })
      })
    } else {
      navigate('./')
    }
  }

  onChangeHandler(name: string, value: any) {
    this.setState({
      formData: this.state.formData.set(name, value)
    })
  }

  onDateTimeChange(date, name) {
    this.onChangeHandler(name, date.toDate())
  }
  onInputChange(event) {
    const { name, value, checked } = event.target
    this.onChangeHandler(name, value || checked)
  }
  onTagChange(value) {
    const tagsList = fromJS(value)
    this.onChangeHandler('caseTags', tagsList)
  }

  render() {
    const { formData, isEditMode, isLoading } = this.state
    const { classes, id } = this.props
    if (isLoading === true) {
      return <Spinner />
    }
    return (
      <>
        {/* <pre>{JSON.stringify(this.state.formData, null, 2)}</pre> */}
        <Snackbar
          place="tc"
          color={this.state.notification.color}
          // icon={faBell}
          message={this.state.notification.message}
          open={this.state.notification.open}
          closeNotification={() =>
            this.setState({ notification: { open: false } })
          }
          close
        />
        <Card>
          <form onSubmit={this.onSubmitHandler} className={classes.form}>
            <CardBody>
              <FormBuilder
                onChange={event => this.onInputChange(event)}
                onDateChange={(date, name) => this.onDateTimeChange(date, name)}
                onTagChange={(value, name) => this.onTagChange(value, name)}
                values={formData}
                classes={classes}
                fields={fields}
                disabled={!isEditMode}
              />

              <GridItem xs={12} sm={12} md={12} lg={12}>
                <EditButtons
                  isEditMode={isEditMode}
                  id={this.props.id}
                  onCancelClick={this.onCancelHandler}
                  toggleEditMode={this.toggleEditMode}
                />
              </GridItem>
            </CardBody>
          </form>
        </Card>

        {/* ===== FILE UPLOAD ==== */}
        {id ? (
          <FileUpload
            isLoading={this.state.fileIsLoading}
            files={formData.get('files')}
            caseId={id}
            isEditMode={isEditMode}
            toggleEditMode={this.toggleEditMode}
          />
        ) : null}

        {id ? (
          <ActionButtonLegis365 id={id} passphrase={formData.get('address')}>
            Send sagen til Legis365
          </ActionButtonLegis365>
        ) : null}
      </>
    )
  }
}

export default withStyles(basicsStyle)(SingleCases)
