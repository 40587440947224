import React from 'react'

import GridContainer from 'components/Grid/GridContainer'
import Button from 'components/CustomButtons/Button.jsx'
import FontAwesomeIcon, {
  faBan,
  faLongArrowLeft,
  faPlus,
  faSave,
  faEdit
} from '../../../FontAwesome'
import { Link } from '@reach/router'

const EditButtons = props => {
  const { isEditMode, id } = props
  return (
    <GridContainer justify="space-between">
      {isEditMode ? (
        <Button onClick={props.onCancelClick} color="danger" simple>
          <FontAwesomeIcon icon={faBan} size="lg" fixedWidth />
          Annuller
        </Button>
      ) : (
        <Link to="./" onClick={() => window.history.back()}>
          <Button simple color="warning">
            <FontAwesomeIcon icon={faLongArrowLeft} size="lg" fixedWidth />
            Tilbage
          </Button>
        </Link>
      )}
      {isEditMode ? (
        <Button key="submit" type="submit" color="success" round>
          <FontAwesomeIcon icon={!id ? faPlus : faSave} size="lg" fixedWidth />
          {!id ? 'Opret' : 'Gem'}
        </Button>
      ) : (
        <Button
          key="edit"
          onClick={props.toggleEditMode}
          color="primary"
          type="button"
          round
        >
          <FontAwesomeIcon icon={faEdit} size="lg" fixedWidth />
          Rediger
        </Button>
      )}
    </GridContainer>
  )
}

export default EditButtons
