import {
  faFolder,
  // faSignOut,
  faClipboardCheck,
  faGavel,
  // faTachometerAlt,
  faColumns,
  faUsers
} from "../FontAwesome";
// core components/views
// import DashboardPage from "views/Dashboard";
// import UserProfile from "views/UserProfile/UserProfile.jsx";
// import TableList from "views/TableList/TableList.jsx";
// import Typography from "views/Typography/Typography.jsx";
// import Icons from "views/Icons/Icons.jsx";
// import Maps from "views/Maps/Maps.jsx";
// import NotificationsPage from "views/Notifications/Notifications.jsx";
// import UpgradeToPro from "views/UpgradeToPro/UpgradeToPro.jsx";

const dashboardRoutes = role => {
  switch (role) {
    case "manager":
      return [
        {
          path: "/dashboard",
          sidebarName: "Dashboard",
          navbarName: "dashboard",
          icon: faColumns
        },
        {
          path: "/cases",
          sidebarName: "Sager",
          navbarName: "cases",
          icon: faGavel
        },
        {
          path: "/rent-check",
          sidebarName: "Husleje Tjek",
          navbarName: "rent-check",
          icon: faClipboardCheck
        },
        {
          path: "/document-access",
          sidebarName: "Aktindsigter",
          navbarName: "document-access",
          icon: faFolder
        },
        {
          path: "/users",
          sidebarName: "Bruger",
          navbarName: "users",
          icon: faUsers
        }
      ];
    case "caseworker":
      return [
        {
          path: "/dashboard",
          sidebarName: "Dashboard",
          navbarName: "dashboard",
          icon: faColumns
        },
        {
          path: "/cases",
          sidebarName: "Sager",
          navbarName: "cases",
          icon: faGavel
        },
        {
          path: "/rent-check",
          sidebarName: "Husleje Tjek",
          navbarName: "rent-check",
          icon: faClipboardCheck
        },
        {
          path: "/document-access",
          sidebarName: "Aktindsigter",
          navbarName: "document-access",
          icon: faFolder
        }
      ];
    default:
      return [];
  }
};

export default dashboardRoutes;

// {
//   path: "/logout",
//   sidebarName: "Log ud",
//   navbarName: "Logout",
//   icon: faSignOut,
//   onClick: () => {
//     swal({
//       title: "Du er på vej ud",
//       text: "Er du sikker?",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true
//     }).then(willLogout => {
//       if (willLogout) {
//         clearToken();
//         window.location.reload();
//       }
//     });
//
//     // window.confirm();
//   }
// }

// { redirect: true, path: "/", to: "/dashboard", navbarName: "Redirect" }
