import React, { Component } from 'react'
import { navigate } from '@reach/router'
import { fromJS } from 'immutable'
// import { toast } from "react-toastify";
import withStyles from '@material-ui/core/styles/withStyles'
import GridItem from 'components/Grid/GridItem'
import Snackbar from 'components/Snackbar/Snackbar'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import Spinner from 'components/UI/Spinner'
import EditButtons from 'components/UI/EditButtons/'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import FileUpload from 'components/UI/FileUpload/FileUpload'

import FormBuilder from 'components/UI/FormBuilder'
import { fields } from './Form/fieldBuilder'
// import { faBell } from "FontAwesome";
import { apiCall } from '../../api'

class SingleDocument extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: Boolean(props.id),
      fileIsLoading: false,
      isEditMode: !props.id,
      formData: fromJS({}),
      notification: {
        open: false,
        color: 'primary',
        message: ''
      }
    }
  }

  componentDidMount() {
    if (this.props.id) {
      apiCall('getSubjectAccess', { id: this.props.id }).then(data => {
        this.setState({ formData: fromJS(data), isLoading: false })
      })
    }
  }

  closeNotification() {
    this.alertTimeout = setTimeout(
      function() {
        this.setState({ notification: { open: false } })
      }.bind(this),
      3000
    )
  }

  toggleEditMode = () => {
    const isEditMode = this.state.isEditMode
    this.setState({ isEditMode: !isEditMode })
  }

  onSubmitHandler = async event => {
    event.preventDefault()
    const { formData } = this.state
    // {===== UPDATE ======}
    if (this.props.id) {
      try {
        this.setState({ isLoading: true })
        const { id: _, files: __, ...input } = formData.toJS()
        await apiCall('updateSubjectAccess', {
          id: this.props.id,
          input: input
        })

        this.setState({
          isLoading: false,
          isEditMode: false,
          notification: {
            open: true,
            color: 'info',
            message: 'Aktindsigten er blevet gemt'
          }
        })
        this.closeNotification()
      } catch (err) {
        console.log(err)
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: 'Kunne ikke gemmes'
          }
        })
        this.closeNotification()
      }
    } else {
      // { ===== CREATE NEW ===== }
      try {
        this.setState({ isLoading: true })
        const data = await apiCall('createSubjectAccess', {
          input: formData.toJS()
        })
        await this.setState({
          isLoading: false,
          isEditMode: false,
          formData: fromJS(data),
          notification: {
            open: true,
            color: 'success',
            message: 'Aktindsigt oprettet'
          }
        })
        await this.closeNotification()
        navigate(`/document-access/${data.id}`)
      } catch (err) {
        console.log(err)
        this.setState({
          isLoading: false,
          notification: {
            open: true,
            color: 'danger',
            message: 'Aktindsigt Kunne ikke oprettes'
          }
        })
        this.closeNotification()
      }
    }
  }

  onCancelHandler = () => {
    if (this.props.id) {
      apiCall('getSubjectAccess', { id: this.props.id }).then(data => {
        this.setState({ formData: fromJS(data), isEditMode: false })
      })
    } else {
      navigate('./')
    }
  }

  onChangeHandler = event => {
    const { value, type, name } = event.target
    if (type === 'number') {
      this.setState({ formData: this.state.formData.set(name, Number(value)) })
    } else {
      this.setState({ formData: this.state.formData.set(name, value) })
    }
  }

  render() {
    const { formData, isEditMode, isLoading } = this.state
    const { classes, id } = this.props

    if (isLoading === true) {
      return <Spinner />
    }
    return (
      <>
        <Snackbar
          place="tc"
          color={this.state.notification.color}
          // icon={faBell}
          message={this.state.notification.message}
          open={this.state.notification.open}
          closeNotification={() =>
            this.setState({ notification: { open: false } })
          }
          close
        />
        <Card>
          <CardBody>
            <form onSubmit={this.onSubmitHandler} className={classes.form}>
              <FormBuilder
                fields={fields}
                values={formData}
                disabled={!isEditMode}
                classes={classes}
                onChange={event => this.onChangeHandler(event)}
              />

              <GridItem xs={12} sm={12} md={12} lg={12}>
                <EditButtons
                  isEditMode={isEditMode}
                  id={this.props.id}
                  onCancelClick={this.onCancelHandler}
                  toggleEditMode={this.toggleEditMode}
                />
              </GridItem>
            </form>
          </CardBody>
        </Card>

        {/* ===== FILE UPLOAD ==== */}
        {id ? (
          <FileUpload
            isLoading={this.state.fileIsLoading}
            files={formData.get('files')}
            caseId={id}
            isEditMode={isEditMode}
            toggleEditMode={this.toggleEditMode}
          />
        ) : null}
      </>
    )
  }
}

export default withStyles(basicsStyle)(SingleDocument)
