import React, { Component } from 'react'
import ChartistGraph from 'react-chartist'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import { dailyCasesChart } from 'variables/charts'
class CasesChart extends Component {
  render() {
    const { classes } = this.props
    return (
      <Card chart>
        <CardHeader color="primary">
          <ChartistGraph
            className="ct-chart"
            // NOTE: data is dummy data and not from a database
            data={dailyCasesChart.data}
            type="Line"
            options={dailyCasesChart.options}
            listener={dailyCasesChart.animation}
          />
        </CardHeader>
        <CardBody>
          <h3 className={classes.cardTitle}>DUMMY DATA</h3>
          <h4 className={classes.cardTitle}>Daglige sager</h4>
          <p className={classes.cardCategory}>
            {/* <span className={classes.successText}>55%</span> flere sager i
            denne uge.*/}
          </p>
        </CardBody>
        <CardFooter chart>
          <div className={classes.stats}>Opdatede 4 minutter siden</div>
        </CardFooter>
      </Card>
    )
  }
}

export default CasesChart
