import React from 'react'
import ReactDOM from 'react-dom'
// import "./index.css";
import App from './App'
import * as serviceWorker from './serviceWorker'
import { Location } from '@reach/router'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-boost'
import { getToken } from './helper'
import { API_ENDPOINT } from './api'

const client = new ApolloClient({
  uri: `${API_ENDPOINT}/graph`,
  request: async operation => {
    const token = await getToken()
    operation.setContext({
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined
      }
    })
  }
})

const app = (
  <ApolloProvider client={client}>
    <Location>
      {props => {
        return <App location={props.location} />
      }}
    </Location>
  </ApolloProvider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
