// @flow
import { fieldData } from './fieldData'

export const fields = () => {
  return [
    {
      field: fieldData.USERNAME,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.EMAIL,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.PHONE,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.FIRST_NAME,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.LAST_NAME,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.INITIALS,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.CITY,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.COUNTRY,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.ZIP_CODE,
      grid: { xs: 12, sm: 12, md: 4, lg: 4 }
      // startAdornment: { icon: faUser }
    },
    {
      field: fieldData.ABOUT_ME,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      rows: 5
    }
  ]
}
