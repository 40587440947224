import React from 'react'
import { navigate } from '@reach/router'
// import fromJS from "immutable";
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'

import loginPageStyle from 'assets/jss/material-kit-pro-react/views/loginPageStyle.jsx'

import FontAwesomeIcon, { faFingerprint, faLockAlt } from '../../FontAwesome'
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser'

import image from 'assets/img/bg2.jpg'
import { getLogin } from '../../api'
import { setToken } from '../../helper'

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: {
        username: '',
        password: ''
      },
      error: false
    }
  }
  // componentDidMount() {
  //   window.scrollTo(0, 0);
  //   document.body.scrollTop = 0;
  // }

  onChangeHandler = event => {
    const { name, value } = event.target
    this.setState({ user: { ...this.state.user, [name]: value } })
  }

  loginHandler = async event => {
    event.preventDefault()
    try {
      const user = await getLogin(this.state.user)
      if (user.token) {
        setToken(user.token)
        this.props.onLogin(user)
        if (document.location.pathname === '/login') {
          navigate('/')
        } else {
          navigate(document.location.pathname)
        }
      }
    } catch (e) {
      this.setState({ error: true })
    }
  }

  render() {
    const { classes } = this.props
    let errorMsg = null
    if (this.state.error === true) {
      errorMsg = (
        <p style={{ color: 'red' }}>Username eller Password er forkert</p>
      )
    }

    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: 'url(' + image + ')',
            backgroundSize: 'cover',
            backgroundPosition: 'top center'
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={4}>
                <Card>
                  <form onSubmit={this.loginHandler} className={classes.form}>
                    <CardHeader
                      color="info"
                      signup
                      className={classes.cardHeader}
                    >
                      <div className={classes.cardTitle}>
                        <h2>Log ind</h2>
                      </div>
                    </CardHeader>
                    <p
                      className={`${classes.description} ${classes.textCenter}`}
                    />
                    <CardBody>
                      {errorMsg}
                      {/* {JSON.stringify(this.state.user)} */}
                      {/* ====== Email ==== */}
                      <CustomInput
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          name: 'username',
                          onChange: event => this.onChangeHandler(event),
                          placeholder: 'Username',
                          type: 'text',
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon
                                icon={faUser}
                                size="lg"
                                fixedWidth
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                      {/* ===== PASSWORD ===== */}
                      <CustomInput
                        id="pass"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          required: true,
                          name: 'password',
                          onChange: event => this.onChangeHandler(event),
                          placeholder: 'Password',
                          type: 'password',
                          startAdornment: (
                            <InputAdornment position="start">
                              <FontAwesomeIcon
                                icon={faLockAlt}
                                size="lg"
                                fixedWidth
                                className={classes.inputIconsColor}
                              />
                            </InputAdornment>
                          )
                        }}
                      />
                    </CardBody>
                    <div className={classes.textCenter}>
                      <Button type="submit" simple color="info" size="lg">
                        <FontAwesomeIcon
                          icon={faFingerprint}
                          size="lg"
                          fixedWidth
                          className={classes.inputIconsColor}
                        />
                        Log ind
                      </Button>
                    </div>
                  </form>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    )
  }
}

export default withStyles(loginPageStyle)(LoginPage)
