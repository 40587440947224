import React, { Component } from 'react'
import { fromJS } from 'immutable'
// used for making the prop types of this component
import PropTypes from 'prop-types'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import ReactTooltip from 'react-tooltip'
// core components
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
// import CardHeader from "components/Card/CardHeader.jsx";
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import FontAwesomeIcon, { faFileUpload } from '../../../FontAwesome'
import Spinner from 'components/UI/Spinner'
import FileUploadInput from './FileUploadInput'
import styles from 'assets/jss/material-kit-pro-react/components/customFileInputStyle.jsx'

import { apiUploadFile } from 'api'

const DUMMY_FILE = fromJS({
  filename: null,
  file: null
})

class FileUpload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      files: this.props.files,
      isLoading: false
    }
  }

  onSubmit = async values => {
    try {
      this.setState({ isLoading: true })
      const data = await apiUploadFile(
        this.props.id,
        values.filter(value => !value.get('id'))
      )
      let files = []
      for (let i = 0; i < data.length; i++) {
        files.push({
          id: data[i].key.split('/')[1],
          filename: data[i].filename
        })
      }
      const allFiles = values
        .filter(value => value.get('id'))
        .concat(fromJS(files))
      this.setState({ files: allFiles })
      this.props.toggleEditMode()
      this.setState({ isLoading: false })
    } catch (error) {
      console.log(error)
      this.setState({ isLoading: false })
    }
  }

  addFile = event => {
    this.setState({
      files: this.state.files.push(
        fromJS({
          filename: event.target.files[0].name,
          file: event.target.files
        })
      )
    })
  }

  render() {
    const { isEditMode, caseId, classes } = this.props
    return (
      <Card className="fileUpload">
        {/* <CardHeader
          color="primary"
          className={classes.cardHeader}
          >
          <h3 className={classes.cardTitleWhite}>Filer</h3>
          <p className={classes.cardCategoryWhite}>Skal der stå noget her?</p>
        </CardHeader> */}
        <CardBody>
          <ReactTooltip
            id="image-preview"
            getContent={url =>
              url && (
                <img
                  src={url + '?preview=1'}
                  alt="Document preview"
                  style={{ width: '480px', maxWidth: '50vw' }}
                />
              )
            }
            effect="solid"
          />
          {this.state.isLoading ? (
            <Spinner />
          ) : (
            this.state.files.concat([DUMMY_FILE]).map((file, idx) => {
              return (
                <FileUploadInput
                  key={file.get('id') || file.get('filename')}
                  caseId={caseId}
                  disabled={isEditMode}
                  classes={classes}
                  onChange={this.addFile}
                  file={file}
                />
              )
            })
          )}
          {isEditMode ? (
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <GridContainer justify="flex-end">
                <Button
                  color="success"
                  onClick={() => this.onSubmit(this.state.files)}
                  // simple
                  round
                >
                  <FontAwesomeIcon icon={faFileUpload} size="lg" fixedWidth />
                  Upload filer
                </Button>
              </GridContainer>
            </GridItem>
          ) : null}
        </CardBody>
      </Card>
    )
  }
}

FileUpload.defaultProps = {
  multiple: false
}

FileUpload.propTypes = {
  id: PropTypes.string,
  endButton: PropTypes.object,
  startButton: PropTypes.object,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  multiple: PropTypes.bool
}

export default withStyles(styles)(FileUpload)
