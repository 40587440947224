import React from 'react'

import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Card from 'components/Card/Card.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardFooter from 'components/Card/CardFooter.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
// import Button from "components/CustomButtons/Button.jsx";
import FontAwesomeIcon, {
  faGavel,
  faClipboardCheck,
  faFolder,
  faArrowUp,
  faArrowDown
} from 'FontAwesome'

import { calcPercents } from 'helper'

const statsCardData = [
  {
    icon: faGavel,
    headLine: 'Sager',
    statsData: 16, // NOTE: DUMMY DATA
    oldStatsData: 3, // NOTE: DUMMY DATA
    grid: { xs: 12, sm: 6, md: 4 }
  },
  {
    icon: faClipboardCheck,
    headLine: 'Husleje Tjek',
    statsData: 20, // NOTE: DUMMY DATA
    oldStatsData: 45, // NOTE: DUMMY DATA
    grid: { xs: 12, sm: 6, md: 4 }
  },
  {
    icon: faFolder,
    headLine: 'Aktindsigter',
    statsData: 8, // NOTE: DUMMY DATA
    oldStatsData: 2, // NOTE: DUMMY DATA
    grid: { xs: 12, sm: 6, md: 4 }
  }
]

const Stats = props => {
  const { classes } = props
  return (
    <GridContainer>
      {statsCardData.map((value, key) => {
        return (
          <GridItem key={key} {...value.grid}>
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <FontAwesomeIcon icon={value.icon} color="white" />
                </CardIcon>
                <p>{value.headLine} DUMMY DATA</p>
                <h3>{value.statsData}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <span
                    className={`percents-number
                      ${
                        value.oldStatsData < value.statsData
                          ? classes.successText
                          : classes.dangerText
                      }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        value.oldStatsData < value.statsData
                          ? faArrowUp
                          : faArrowDown
                      }
                    />
                    {calcPercents(value.oldStatsData, value.statsData)}%
                  </span>
                  {`${
                    value.oldStatsData < value.statsData ? 'flere' : 'færre'
                  } ${value.headLine.toLowerCase()}, i denne måned`}
                </div>
                {/* <Button type="button" size="sm">
                  24 timer
                </Button>
                <Button type="button" size="sm">
                  7 dag
                </Button>
                <Button type="button" size="sm">
                  1 måned
                </Button> */}
              </CardFooter>
            </Card>
          </GridItem>
        )
      })}

      {/* <GridItem xs={12} sm={6} md={4}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              <FontAwesomeIcon icon={faClipboardCheck} color="white" />
            </CardIcon>
            <p>Husleje Tjek</p>
            <h3>8</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Link to="/rent-check">Alle husleje tjek</Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem>
      <GridItem xs={12} sm={6} md={4}>
        <Card>
          <CardHeader color="primary" stats icon>
            <CardIcon color="primary">
              <FontAwesomeIcon icon={faFolder} color="white" />
            </CardIcon>
            <p>Aktindsigter</p>
            <h3>6</h3>
          </CardHeader>
          <CardFooter stats>
            <div className={classes.stats}>
              <Link to="/document-access">Alle aktindsigter</Link>
            </div>
          </CardFooter>
        </Card>
      </GridItem> */}
    </GridContainer>
  )
}

export default Stats
