import React from 'react'
import classNames from 'classnames'
import { navigate } from '@reach/router'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Hidden from '@material-ui/core/Hidden'
import Poppers from '@material-ui/core/Popper'
// core components
// import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import FontAwesomeIcon, {
  // faBell,
  // faSearch,
  faCog,
  faSignOut
} from 'FontAwesome'
import headerLinksStyle from 'assets/jss/material-dashboard-react/components/headerLinksStyle.jsx'
import swal from 'sweetalert'
import { clearToken } from 'helper'
import { Query } from 'react-apollo'
import gql from 'graphql-tag'

const GET_ME = gql`
  {
    me {
      name
    }
  }
`

class HeaderLinks extends React.Component {
  state = {
    open: false,
    notifications: ['Du har fået tildelt en ny sag']
  }
  handleToggle = () => {
    this.setState(state => ({ open: !state.open }))
  }

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return
    }

    this.setState({ open: false })
  }

  render() {
    const { classes } = this.props
    const { open } = this.state
    return (
      <div>
        {/* <div className={classes.searchWrapper}>
          <CustomInput
            formControlProps={{
              className: classes.margin + ' ' + classes.search
            }}
            inputProps={{
              placeholder: 'Search',
              inputProps: {
                'aria-label': 'Search'
              }
            }}
          />
          <Button color='white' aria-label='edit' justIcon round>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div> */}

        <div className={classes.manager}>
          {/* <Button
            buttonRef={node => {
              this.anchorEl = node;
            }}
            id="notification"
            color={window.innerWidth > 959 ? "transparent" : "white"}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? "menu-list-grow" : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <FontAwesomeIcon icon={faBell} className={classes.icons} />
            <span className={classes.notifications}>
              {this.state.notifications.length}
            </span>
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Notification
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      {this.state.settings.map((item, idx) => {
                        return (
                          <MenuItem
                            key={idx}
                            onClick={this.handleClose}
                            className={classes.dropdownItem}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers> */}
          <Button
            buttonRef={node => {
              this.anchorEl = node
            }}
            id="settings"
            color={window.innerWidth > 959 ? 'transparent' : 'white'}
            justIcon={window.innerWidth > 959}
            simple={!(window.innerWidth > 959)}
            aria-owns={open ? 'menu-list-grow' : null}
            aria-haspopup="true"
            onClick={this.handleToggle}
            className={classes.buttonLink}
          >
            <FontAwesomeIcon icon={faCog} className={classes.icons} />
            <Hidden mdUp implementation="css">
              <p onClick={this.handleClick} className={classes.linkText}>
                Settings
              </p>
            </Hidden>
          </Button>
          <Poppers
            open={open}
            anchorEl={this.anchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !open }) +
              ' ' +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={this.handleClose}>
                    <MenuList role="menu">
                      <Query query={GET_ME}>
                        {({ client }) => {
                          // console.log(client);
                          return (
                            <MenuItem
                              onClick={() => {
                                swal({
                                  title: 'Du er på vej ud',
                                  text: 'Er du sikker på, at du vil logge ud?',
                                  icon: 'warning',
                                  buttons: true,
                                  dangerMode: true
                                }).then(willLogout => {
                                  if (willLogout) {
                                    client.clearStore().then(() => {
                                      clearToken()
                                      navigate('/login')
                                    })
                                  }
                                })
                              }}
                              className={classes.dropdownItem}
                            >
                              <FontAwesomeIcon
                                icon={faSignOut}
                                className={classes.icons}
                              />{' '}
                              Log ud
                            </MenuItem>
                          )
                        }}
                      </Query>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        </div>
      </div>
    )
  }
}

export default withStyles(headerLinksStyle)(HeaderLinks)
