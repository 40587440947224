/* eslint-disable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Router } from '@reach/router'

// creates a beautiful scrollbar
// import PerfectScrollbar from 'perfect-scrollbar';
// import 'perfect-scrollbar/css/perfect-scrollbar.css';

import withStyles from '@material-ui/core/styles/withStyles'
// core components
import Header from 'components/Header/Header'
import Sidebar from 'components/Sidebar/Sidebar'
import Footer from 'components/Footer/Footer'
import sidebarLinks from 'routes/sidebarNav'
import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle'
import image from 'assets/img/sidebar-2.jpg'
import logo from 'assets/img/DIGURA-logo.png'

import User from 'views/UserProfile/User'
import Dashboard from 'views/Dashboard'
import AllRentCheck from 'views/RentCheck/AllRentCheck'
import SingleRentCheck from 'views/RentCheck/SingleRentCheck'
import AllDocument from 'views/DocumentAccess/AllDocument'
import SingleDocument from 'views/DocumentAccess/SingleDocument'
import AllCases from 'views/Cases/AllCases'
import SingleCases from 'views/Cases/SingleCases'
import AllUsers from 'views/Users/AllUsers'
import SingleUsers from 'views/Users/SingleUsers'
import NotFound from 'views/404'
import { fromJS } from 'immutable'
import Login from 'views/Login/Login'
import { getToken, clearToken, setToken, parseJwt } from './helper'
import { getLogin } from './api'
import './App.css'
const RANDOM_AVATAR = 70289

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileOpen: false,
      user: fromJS({})
    }
    this.resizeFunction = this.resizeFunction.bind(this)
    this.meCheck()
  }

  meCheck = () => {
    if (getToken()) {
      if (parseJwt(getToken()).exp < Math.floor(new Date().getTime() / 1000)) {
        clearToken()
      } else {
        getLogin().then(data => {
          this.setState({
            user: fromJS(data)
          })
        })
      }
    }
    return
  }

  userHandler = auth => {
    this.setState({ user: fromJS(auth) })
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen })
  }
  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false })
    }
  }

  componentDidMount() {
    // if (navigator.platform.indexOf('Win') > -1) {
    //   const ps = new PerfectScrollbar(this.refs.mainPanel);
    // }
    window.addEventListener('resize', this.resizeFunction)
  }
  // componentDidUpdate(e) {
  //   // NOTE: Kig i trello under note
  //   console.log(e);
  //   console.log(e.history);
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction)
  }

  render() {
    const { classes, ...rest } = this.props
    if (this.state.user === null) {
      clearToken()
    }
    return getToken() ? (
      <div className={classes.wrapper}>
        <Sidebar
          routes={sidebarLinks(this.state.user.get('role'))}
          logoText={'Digura'}
          username={this.state.user.get('name')}
          userImg={`https://api.adorable.io/avatars/${RANDOM_AVATAR}/`}
          logo={logo}
          backgroundImage={image}
          handleDrawerToggle={this.handleDrawerToggle}
          open={this.state.mobileOpen}
          color="blue"
          {...rest}
        />
        <div className={classes.mainPanel} /* ref='mainPanel'*/>
          <Header
            routes={sidebarLinks(this.state.user.get('role'))}
            handleDrawerToggle={this.handleDrawerToggle}
            {...rest}
          />
          <div className={classes.content}>
            {/* <div className={classes.container}> */}
            <Router>
              {/* DASHBOARD */}
              <Redirect from="/" to="/dashboard" noThrow />
              <Dashboard path="/dashboard" />
              {/* /DASHBOARD */}

              {/* USER PROFILE */}
              <User
                path="/profile"
                userImg={`https://api.adorable.io/avatars/${RANDOM_AVATAR}/`}
                workTitle={'Webdeveloper'}
                user={this.state.user}
              />
              {/* /USER PROFILE */}

              {/* Cases */}
              <AllCases path="/cases" />
              <SingleCases path="/cases/:id" />
              <SingleCases path="/cases/new" />
              {/* /Cases */}

              {/* RENT CHECK */}
              <AllRentCheck path="/rent-check" />
              <SingleRentCheck path="/rent-check/:id" />
              {/* /RENT CHECK */}

              {/* DOCUMENT ACCESS */}
              <AllDocument path="/document-access" />
              <SingleDocument path="/document-access/:id" />
              <SingleDocument path="/document-access/new" />
              {/* /DOCUMENT ACCESS */}

              {/* USERS */}
              {/* {this.state.user.get("role") !== "manager" ? (
                <Redirect from="/users" to="/dashboard" noThrow />
              ) : null} */}
              <AllUsers path="/users" />
              <SingleUsers path="/users/:id" />
              <SingleUsers path="/users/new" />

              {/* /USERS */}

              <NotFound default />
            </Router>
            {/* </div> */}
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    ) : (
      <Router>
        <Redirect from="/logout" to="/login" noThrow />
        <Login default onLogin={this.userHandler} />
      </Router>
    )
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(dashboardStyle)(App)
