// @flow
import { fieldData } from './fieldData'

import {
  faUser,
  faEnvelope,
  faMobileAlt,
  faHome,
  faRulerCombined,
  faMoneyBillWaveAlt,
  faPersonDolly,
  // faBan,
  faWrench,
  faIdBadge
  // faLongArrowLeft,
  // faPlus,
  // faSave,
  // faEdit
} from '../../../FontAwesome'

export const fields = () => {
  return [
    {
      field: fieldData.CONTACT_NAME,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faUser }
    },
    {
      field: fieldData.CONTACT_EMAIL,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faEnvelope }
    },
    {
      field: fieldData.CONTACT_PHONE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faMobileAlt }
    },
    {
      field: fieldData.ADDRESS,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faHome }
    },
    {
      field: fieldData.ADDRESS_ID,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faIdBadge }
    },
    {
      field: fieldData.SIZE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faRulerCombined },
      endAdornment: { text: 'm2' }
    },
    {
      field: fieldData.BBR_SIZE,
      grid: { xs: 12, sm: 6, md: 6, lg: 6 },
      startAdornment: { icon: faRulerCombined },
      endAdornment: { text: 'm2' }
    },
    {
      field: fieldData.RENT,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faMoneyBillWaveAlt }
    },
    {
      field: fieldData.CONDITION,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faWrench }
    },
    {
      field: fieldData.MOVE_IN,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faPersonDolly }
    },
    {
      field: fieldData.FURNISHED,
      grid: { xs: 12, sm: 12, md: 12, lg: 12 },
      startAdornment: { icon: faUser }
    }
  ]
}
