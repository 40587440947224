import React from 'react'
import { Link } from '@reach/router'
import withStyles from '@material-ui/core/styles/withStyles'
import basicsStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/basicsStyle.jsx'
import gql from 'graphql-tag'
import { Query } from 'react-apollo'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Button from 'components/CustomButtons/Button.jsx'
import FontAwesomeIcon, { faFilePlus } from 'FontAwesome'

const caseOptions = gql`
  query caseOptions {
    caseOptions {
      title
      value
    }
  }
`

const CategoriesSelecter = props => {
  const { classes, value, label, name, onChange, disabled } = props

  return (
    <Query query={caseOptions}>
      {({ data }) => {
        return (
          <>
            <Link to={`new`}>
              <Button color="success">
                <FontAwesomeIcon icon={faFilePlus} size="lg" fixedWidth />
                Opret
              </Button>
            </Link>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor={name} className={classes.selectLabel}>
                {label}
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={value.get('caseType') || ''}
                onChange={onChange}
                inputProps={{
                  name: name,
                  required: true,
                  disabled: disabled,
                  id: name
                }}
              >
                {(data.caseOptions || []).map(({ title, value }) => {
                  return (
                    <MenuItem
                      key={value}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected
                      }}
                      value={value}
                    >
                      {title}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </>
        )
      }}
    </Query>
  )
}

export default withStyles(basicsStyle)(CategoriesSelecter)
